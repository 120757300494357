html, body 
{
    width:100%;
    min-width:100%;
    height:100%;
    min-height:100%;
    padding:0;
    margin:0;

}
.App {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 15px;
    box-sizing:border-box;
}


/* Small devices (landscape phones, 576px and up) */
@media (min-width: 479px) {

}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 639px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 1023px) {
    
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1365px) {
    .App
    {
        width: 1200px;
    }
}

/* Extra Extra large devices (large desktops, 1919px and up) */
@media (min-width: 1919px) {

}
